<template>
  <div class="sg-container">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>{{ title }}</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="create"
          >新建</el-button
        >
      </div>
      <div class="sg-margin-bottom">
        <el-button type="warning" size="mini" @click="saveSort">拖曳排序，点此保存</el-button>
      </div>
      <el-tree
        :data="list"
        :indent="24"
        node-key="id"
        draggable
        default-expand-all
        :expand-on-click-node="false"
      >
        <span
          class="sg-tree-node sg-space-between sg-w100"
          slot-scope="{ data }"
        >
          <span>
            <i :class="data.icon"></i>
            <span class="sg-pad-lr">{{ data.name }}</span>
            <el-tag type="success" effect="dark" class="sg-margin-right">{{ data.url }}</el-tag>
          </span>
          <span>
            <el-button :type="data.show == 2 ? 'info' : 'success'" size="mini" @click="editShow(data)">{{ data.show == 2 ? '隐藏' : '显示' }}</el-button>
            <el-button type="primary" size="mini" @click="edit(data)"
              >编辑</el-button
            >
            <el-button type="danger" size="mini" @click="deleteRow(data)"
              >删除</el-button
            >
          </span>
        </span>
      </el-tree>
    </el-card>
    <el-dialog
      :title="action == 'create' ? '新建' : '编辑'"
      :visible.sync="showDialog"
      width="60%"
      top="5vh"
    >
      <div class="sg-dialog-body">
        <el-row>
          <el-col :sm="{ span: 18, offset: 3 }">
            <el-form
              ref="createEditForm"
              :model="item"
              label-width="80px"
              :rules="rules"
            >
              <el-form-item prop="name" label="菜单名称">
                <el-input
                  v-model="item.name"
                  placeholder="请输入菜单名称"
                  class="sg-login-input"
                ></el-input>
              </el-form-item>
              <el-form-item prop="icon" label="图标">
                <el-input
                  v-model="item.icon"
                  placeholder="请输入图标"
                  class="sg-login-input"
                ></el-input>
              </el-form-item>
              <el-form-item prop="url" label="链接">
                <el-input
                  v-model="item.url"
                  placeholder="请输入链接"
                  class="sg-login-input"
                ></el-input>
              </el-form-item>
              <el-form-item prop="show" label="显示/隐藏">
                <el-radio-group v-model="item.show">
                  <el-radio :label="1">显示</el-radio>
                  <el-radio :label="2">隐藏</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submit">提交</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
// @ is an alias to /src
// import { mapState } from "vuex"

export default {
  name: "AdminMenu",
  data() {
    return {
      title: "菜单管理",
      total: 0,
      pageSize: 15,
      current: 1,
      showDialog: false,
      pre_uri: "admin-menus/",
      // create/update
      action: "create",
      emptyItem: {
        name: "",
        icon: "",
        url: "",
        show: 1
      },
      item: {},
      searchItem: {
        name: "",
      },
      rules: {
        name: [{ required: true, message: "请输入菜单名称", trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapState({
      list: (state) => state.admin.admin_menus,
    }),
    totalPages: () => {
      if (this.total) return Math.ceil(this.total / this.pageSize);
      else return 1;
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    saveSort() {
      this.$http
        .post(this.pre_uri + "saveSort", {
          list: this.list,
        })
        .then((res) => {
          if (res.code == 0) this.getList();
        });
    },
    search() {
      this.current = 1;
      this.getList();
    },
    getList() {
      this.$store.dispatch("admin/getList", {
        url: this.pre_uri + "get",
        key: "admin_menus",
      });
    },
    pageChange(current) {
      this.current = current;
      this.getList();
    },
    create() {
      this.item = Object.assign({}, this.emptyItem);
      this.action = "create";
      this.switchShowDialog(true);
    },
    editShow(item) {
      this.item = Object.assign({}, item, {
        show: item.show == 2 ? 1 : 2
      })
      this.action = 'edit';
      this.submit()
    },
    edit(item) {
      this.item = Object.assign({}, item);
      this.action = "edit";
      this.switchShowDialog(true);
    },
    deleteRow(row) {
      this.$confirm("确定删除该菜单及其所有子菜单吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post(this.pre_uri + "delete", {
              id: row.id,
            })
            .then((res) => {
              if (res.code == 0) {
                this.getList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    switchShowDialog(show) {
      this.showDialog = show;
    },
    submit() {
      var url = this.pre_uri + (this.action == "create" ? "store" : "update");
      this.$http.post(url, this.item).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.switchShowDialog(false);
          this.getList();
        }
      });
    },
  },
};
</script>
